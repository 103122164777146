import { blueGrey } from '@mui/material/colors'

import type { ThemeUpdateOptions } from './types'

// ----------------------------------------------------------------------

const overridesTheme: ThemeUpdateOptions = {
  colorSchemes: {
    light: { palette: { background: { default: blueGrey[50] } } }
  }
}

export default overridesTheme
