import { type ReactNode } from 'react'

import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFnsV3'

import ThemeProvider from '@repo/theme'
import { SnackbarProvider } from 'notistack'

export const MAX_SNACKS = 3

interface UiProviderProps {
  children: ReactNode
}

const UiProvider = (props: UiProviderProps): JSX.Element => {
  const { children } = props

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider maxSnack={MAX_SNACKS}>{children}</SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default UiProvider
