import type { Theme } from '@mui/material/styles'

import { extendTheme } from '@mui/material/styles'

import overridesTheme from './overridesTheme'
import { shadows, typography, components, colorSchemes, customShadows } from './core'
import type { ThemeColorScheme } from './types'

// ----------------------------------------------------------------------

function shouldSkipGeneratingVar(keys: string[], value: string | number): boolean {
  const skipGlobalKeys = [
    'mixins',
    'overlays',
    'direction',
    'breakpoints',
    'cssVarPrefix',
    'unstable_sxConfig',
    'typography'
    // 'transitions',
  ]

  const skipPaletteKeys: {
    [key: string]: string[]
  } = {
    global: ['tonalOffset', 'dividerChannel', 'contrastThreshold'],
    grey: ['A100', 'A200', 'A400', 'A700'],
    text: ['icon']
  }

  const isPaletteKey = keys[0] === 'palette'

  if (isPaletteKey) {
    const paletteType = keys[1]
    const skipKeys = skipPaletteKeys[paletteType] || skipPaletteKeys.global

    return keys.some((key) => skipKeys?.includes(key))
  }

  return keys.some((key) => skipGlobalKeys?.includes(key))
}

const createTheme = (mode: ThemeColorScheme = 'light'): Theme => {
  const initialTheme = {
    colorSchemes,
    shadows: shadows(mode),
    customShadows: customShadows(mode),
    shape: { borderRadius: 8 },
    components,
    typography,
    cssVarPrefix: '',
    shouldSkipGeneratingVar
  }

  const theme = extendTheme(initialTheme, overridesTheme)

  return theme
}

export default createTheme
