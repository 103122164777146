import type { Theme, Components } from '@mui/material/styles'

// ----------------------------------------------------------------------

// MUITreeItem is not on the theme type just yet.
/* @ts-expect-error */
const MuiTreeItem: Components<Theme>['MuiTreeItem'] = {
  /** **************************************
   * STYLE
   *************************************** */
  styleOverrides: {
    label: ({ theme }: { theme: Theme }) => ({ ...theme.typography.body2 }),
    iconContainer: { width: 'auto' }
  }
}

// ----------------------------------------------------------------------

export const treeView = { MuiTreeItem }
